
import BellButton from './notification/BellButton.vue'

export default {
  name: 'main-header',
  components: {
    BellButton
  },
  data() {
    return {
      isDashboard: false,
      navColor: '',
      isLoginPage: false
    }
  },
  methods: {
    navigateToBookASession() {
      if (this.userInfo.stripeSubscriptionId || this.admin) {
        this.$router.push('/book-session')
      } else {
        this.$snotify.warning(
          'You must have a active subscription to have access to the book a session calendar.'
        )
      }
    },
    navigateToAccountInfo() {
      const tabNumber = 6
      this.$store.commit('SET_PROFILETAB', tabNumber)
      localStorage.profileTab = tabNumber
      if (!this.$route.path.match('/dashboard')) {
        this.$router.push('/dashboard')
      }
      return true
    },
    navbarColor() {
      if (process.browser) {
        if (this.$route.path === '/' && window.scrollY < 100) {
          this.navColor = 'transparent no-shadow'
          // this.navColor = 'bg-black';
        } else if (this.$route.path === '/' && window.scrollY > 100) {
          this.navColor = 'bg-black'
        } else {
          this.navColor = 'bg-black'
        }
      }
    },
    manageUserBtn() {
      if (this.user) {
        if (this.$route.path.includes('/dashboard')) {
          this.isDashboard = true
        } else if (this.$route.path === '/login') {
          this.isLoginPage = true
        } else {
          this.isDashboard = false
          this.isLoginPage = false
        }
      }
    },
    logout(e) {
      e.preventDefault()
      this.$router.push('/login')
      this.$store.dispatch('logout')
    }
  },
  async mounted() {
    if (this.user && !this.userInfo.email) {
      const clientId = localStorage.getItem('clientId')
      await this.$store.dispatch('getUserInfo', clientId || this.user)
    }
    this.$fb.setUserData({ fireBaseId: this.user, email: this.userInfo.email })
    this.$fb.enable()
    this.manageUserBtn()
    this.navbarColor()
    if (!this.user && !this.admin) {
      localStorage.removeItem('clientId')
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.navbarColor)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.navbarColor)
  },
  watch: {
    $route(to) {
      if (to.name !== 'dashboard') {
        this.$store.commit('SET_ISPROFILEPAGE', false)
      } else {
        this.$store.commit('SET_ISPROFILEPAGE', true)
      }
      this.manageUserBtn()
      this.navbarColor()
      this.$store.commit('SET_USER_INFO', {})
      this.$store.commit('SET_CHECKINS', [])
      this.$store.commit('SET_FITNESS_PROGRAM', [])
      this.$store.commit('SET_CHART', [])
      this.$store.commit('SET_NUTRITIONS', null)
      this.$store.commit('SET_RECIPES', null)
      this.$store.commit('SET_GROCERIES', null)
      this.$store.commit('SET_LEVELOFFITNESS', null)
      this.$store.commit('SET_MEASUREMENTS', null)
      this.$store.commit('SET_TRAINER_NOTES', null)
      this.$store.commit('SET_DAY_WORKOUT_TYPE', null)
      this.$store.commit('SET_SUBSCRIPTION_STATUS', null)
      this.$store.commit('SET_SUB_STATUS', null)
      this.$store.commit('SET_USER_PROGRAMS', [])
      this.$store.commit('SET_SELECTED_PROGRAM', null)
      this.$store.dispatch('clientNextInvoice')
    }
  },
  computed: {
    user() {
      return this.$store.state.user
    },
    admin() {
      return this.$store.state.admin
    },
    userInfo() {
      return this.$store.state.userInfo
    }
  }
}
