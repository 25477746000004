import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=d765c526"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=d765c526&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Logo: require('/Users/tdrdimov/gogoproj/fitapp - current GIT/fitnhealthy/src/components/Logo.vue').default,SlideOut: require('/Users/tdrdimov/gogoproj/fitapp - current GIT/fitnhealthy/src/components/SlideOut.vue').default})
